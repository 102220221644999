<template>
  <v-card flat wrap class="ma-5" @click="toActionHistory(kid._id, kid.name)">
    <v-row align="center" justify="space-between" class="card veryLightPurple">
      <v-col cols="2">
        <v-avatar class="avatar">
          <v-img :src="`/imgs/users/${kid.color}.png`" lazy-src="/person.png" />
        </v-avatar>
      </v-col>
      <v-col cols="7">
        <div class="kid-name">
          {{ kid.name }}
        </div>
        <div class="kid-balance">
          <span>{{ Math.abs(balance) }} {{ balance >= 0 ? "" : "-" }}</span>
          ₪
        </div>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-btn
          @click="toPayKid(kid._id, balance)"
          class="gradient-btn pa-4"
          x-small
          v-text="'פעולה'"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "KidCard",
  props: { kid: Object },
  computed: {
    balance() {
      return this.kid.balance;
    },
  },
  methods: {
    toActionHistory(id) {
      this.$router.push("/action-history/" + id);
    },
    toPayKid(id) {
      this.$router.push(`/kid/${id}`);
    },
  },
};
</script>
