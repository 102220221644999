<template>
  <div class="home header-color" v-if="user">
    <my-header
      btnRight="mdi-menu"
      :title="`הארנק של משפחת ${user.lastName}`"
      btnLeft="mdi-account-edit"
      @clickBtnLeft="openSetting"
      @clickBtnRight="openMenu"
      myClass
    >
      <template v-slot:morBtnLeft>
        <v-icon @click="openSheet(true)">mdi-account-plus</v-icon>
      </template>
    </my-header>

    <v-container
      class="form-container d-flex justify-center flex-column body-color"
    >
      <div class="d-flex justify-center">
        <div class="avatar-img d-flex justify-center mt-n16">
          <v-avatar size="126" class="avatar">
            <img src="/person.png" lazy-src="/person.png" />
          </v-avatar>
        </div>
      </div>
      <div class="hllow-user d-flex justify-center" v-if="user">
        שלום,
        <br />
        {{ user.firstName }}
        {{ user.lastName }}
      </div>
      <v-container>
        <v-card-text class="messeg mb-1" v-if="user.kids.length">
          הילדים שלך:
        </v-card-text>
        <kid-card
          v-for="kid in user.kids"
          :key="kid._id"
          :kid="kid"
          @openSheet="openSheet"
        />
      </v-container>

      <v-row class="justify-center mt-10 pa-10">
        <handle-kid
          v-model="sheet"
          @handleKid="handleKid"
          :isNew="isNew"
          :fieldParameter="fieldParameter"
        />
      </v-row>
    </v-container>
    <v-footer app class="center-all mb-10" style="background: center">
      <v-bottom-sheet v-model="selectColor" max-width="700px">
        <v-sheet max-height="100%" class="py-10">
          <select-color-avatar @setSelectColor="setSelectColor" />
        </v-sheet>
      </v-bottom-sheet>
    </v-footer>
  </div>
</template>

<script>
import MyHeader from "../components/MyHeader.vue";
import HandleKid from "../components/HandleKid.vue";
import KidCard from "../components/KidCard.vue";
import SelectColorAvatar from "../components/SelectColorAvatar.vue";

export default {
  components: { MyHeader, HandleKid, KidCard, SelectColorAvatar },
  name: "Home",
  data() {
    return {
      sheet: false,
      isNew: false,
      fieldParameter: [],
      showAlertKidURL: false,
      selectColor: false,
    };
  },
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },
    openMenu() {
      this.$store.commit("drawer/set", true);
    },
    setSelectColor() {
      this.selectColor = !this.selectColor;
      this.$store.commit("kid/unset");
    },
    async handleKid(parameters) {
      const { isNew, name } = parameters;
      delete parameters.isNew;
      if (isNew === "editSetting") {
        return this.editSetting(parameters);
      } else {
        return this.addKid(name);
      }
    },
    async addKid(name) {
      await this.$store.dispatch("user/add-kid", {
        kids: [{ name }],
      });
      this.$store.getters.kid;
      this.selectColor = true;
      this.sheet = false;
    },

    openSheet(isNew) {
      isNew ? this.$store.commit("kid/unset") : null;
      this.fieldParameter = [{ name: "name", label: "שם פרטי" }];
      this.isNew = isNew;
      this.sheet = true;
    },
    openSetting() {
      this.fieldParameter = [
        { name: "firstName", label: "שם פרטי" },
        { name: "lastName", label: "שם משפחה" },
        { name: "email", label: "אימייל" },
        { name: "phone", label: "פלאפון" },
        { name: "address", label: "כתובת" },
      ];
      this.sheet = true;
    },
    async editSetting(parameters) {
      await this.$store.dispatch("user/update", parameters);
      this.sheet = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  beforeMount() {
    this.$store.commit("kid/unset");
    this.$store.commit("action/unset");
  },
};
</script>

<style lang="scss" scoped>
.card {
  border-radius: 9px !important;
}

.home > .header {
  height: 160px;
}
</style>
